




@media only screen and ( max-width: 10000px) and (min-width : 300px) {
  
  .App{
    position: relative;
      font-family: 'Roboto', sans-serif;
    background-color: #fff9c9;
    overflow: auto;
    height: 100vh;
    scroll-behavior: smooth;
    white-space: pre-line;
    scrollbar-color: none;
    scrollbar-width: 2px;
    }

    .nav{
      background-color: #e8702a;
      box-shadow: 0px 5px 5px #010102;
      position: fixed;
      z-index: 999;
      display: flex;
      justify-content:space-between ;
      font-size: medium;
      padding-right: 10px;
      width: 100vw;
    }
    .navButton{
      position: relative;
      display: flex;
      width: fit-content;
      height: fit-content;
      top: 10px;
      padding-left: 10px;
    
    }
  .noDisplay{
    display: none;
  }


  
   /* ---- event box ----*/



  .event_hover{
    position: relative;
    justify-content: left;
    color: aliceblue;
    border-radius: 10px ;
    box-shadow: 0 0 5px rgba(157, 153, 176, 0.546);
    margin: 15px 15px 15px 15px;
    
    p{
      padding-left: 20px;
      text-align: left;
    }
  
  }



  .event_hover_p{
    text-align: center;
    padding-bottom: 10px;
  
    button{
      background-color: rgba(255, 0, 0, 0);
      border: solid rgb(244, 160, 34);
      color: rgb(244, 160, 34);
    }
  
  }

  .event_header{
    display: flex;
    position: relative;
    justify-content: space-between;
    background-color: #0c457d;
    padding-left: 15px;
    padding-right: 10px;
    
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    color: rgb(251, 226, 180);
      h1{
      font-size: calc(15px + 2vmin);
    }
  
    a{
      margin-top: 5px;
      height: auto;
       img{
        height: 35px;
      border: solid 1px rgb(244, 173, 60);
      border-radius: 20px;
  
       }
    }
  }

  

  .guestImg{
    background-color: #ffbe4f;
    padding-bottom: 10px;
    margin-top: 1px;
    border: solid #ffbe4f;
    img {
      transform: translate(0,10px);
    }
  }



  .btn_grp_home{
    position: relative;
    display: block;
    text-align: right;
    background-color:		#0c457d;
    height: 7vh;
    border-top: 2px solid rgb(113, 42, 42);
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    color: 	#6bd2db;
    
     h3{
      font-size: medium;
      padding-right: 15px;
     }
   }






  

.btnDisabled{
  color: rgb(255, 255, 255);
  padding: 10px;
  border-radius: 50px;
  font-size: medium;
  background-color: #515356;

  
}

.menuBtn{
  background-color: rgba(220, 220, 1, 0);
    

    img{
      margin-top: 15px;
      margin-right: 10px;
    }
}



.yesAnim{
  transform: scale(1.2);
  transition: transform .3s;
  margin-left: 10px;

}

.noAnim{
  margin-left: 10px;
  transform: scale(1);
  transition: transform .3s;
}

.sideNavDiv{
  width: fit-content;
  text-align: left;
}
.closedSideNav{
  position: absolute;
  display: flex;
    justify-content: center;
  z-index: 9999;
  background-color: rgba(25, 21, 21, 0.584);
  box-shadow: 0px 5px 5px #010102;
  
  color: wheat;
  width: 100vw;
  height: 40vh;
  text-align: center;
  transform: scaleY(0);
  transform-origin: top;
  margin-top: 50px;
  transition: transform .3s;
  border-bottom-right-radius: 20px;
  border-bottom-left-radius: 20px;

  
  img{
    height: 30px;
    transform: translateY(10px);
    margin-right: 10px;
  }


  button{
    margin-top: 10px;
    background-color: rgba(252, 108, 108, 0);
    border: solid red 1px;
    color: white;
  }

  table{
    margin-top: 40px;
  }


}



.openSideNav{
    position: fixed;
    display: flex;
    justify-content: center;
  z-index: 9999;
  background-color: rgb(25, 21, 21);
  box-shadow: 0px 5px 5px #010102;
  color: wheat;
  width: 100vw;
  height: 40vh;
  text-align: center;
  transform: scaleY(1);
  transform-origin: top;
  margin-top: 60px;
  transition: transform .3s;
  border-bottom-right-radius: 20px;
  border-bottom-left-radius: 20px;

  img{
    height: 25px;
    margin-right: 10px;
  }


  

  table{
    margin-top: 40px;
  }

}

table th{
  font-size: 18px;
  padding-top: 10px;
}



.logoutTable{
  display: flex;
  
  transform: translate(30vw,0px);

  button{
    border: solid red 1px;
    background-color: #00000000;
    color: white;
  }

  img{
    height: 30px;

    }
}



.isActive{
  background-color: orange;
  color: black;
  font-weight: 500;
  border: solid white;
  transform: scale(1.1);
  transition: 10ms;
}

.notActive{
  background-color: rgb(0, 0, 0);
  color: white;
  border: solid orange;
}

.success{
  position: absolute;
  text-align: center;
  width: 100%;
  margin-top: 20%;
  background-color: orange;
  z-index: 999;
  color: rgb(0, 0, 0);
   
  p{
    font-size: large;
  }

  button{
    background-color: #00000000;
    border: 1px solid black;
    font-weight: 600;
  }
}

.noEvent{
 display: none;
}
.number{
  color: rgba(255, 166, 0, 0);
  margin-top: -10px;
}
.noMyEvent{
  text-align: center;
  font-size: large;
  background-color: orange  ;
  color: #000000;
  border-top: dashed black;
  border-bottom: dashed black;
  margin-top: 40%;
  width: 100%;
}

.btnDisabled:hover{
  background-color: rgb(81, 83, 86);
  border: none;
}

button:disabled{
  color: rgb(255, 255, 255);
  padding: 10px;
  border-radius: 50px;
  font-size: medium;
  background-color: rgb(81, 83, 86);

}

button:disabled:hover{
  color: rgb(255, 255, 255);
  padding: 10px;
  border-radius: 50px;
  font-size: medium;
  background-color: rgb(81, 83, 86);
  border: none;
}

.eventbottom{
  position: sticky;
  display: flex;
  bottom: 0;
  background-color: #000000dc;
  z-index: 999;
  width: fit-content;
  border-radius: 50px
}


.App-header {
  background-color: #282c34;
  font-size: calc(10px + 2vmin);
  color: white;
  position: relative;
  justify-content: right;
  margin: 5px;
  text-wrap: nowrap;
  position: relative;
  display: flex;
  justify-content: space-evenly;

  button{
    width: 100px;
  }
}

body {
  border: 0;
  margin: 0;
  padding: 0;
  font-family: sans-serif;
  background-color: rgba(250, 250, 250)
}

.container {
  min-height: 100vh;
  justify-content: center;
  align-items: center;
  display: flex;
  text-align: center;
}

.header h1{

 
  text-align: center;
  justify-content: center;
  font-size: calc(30px + 2vmin);
  color: white;
  text-shadow: 0.05em 0.1em #4b5d83;
  backdrop-filter: blur(10px) saturate(70%)

}






button {
  cursor: pointer;
  padding: 5px;
  margin: 2px;
  border: none;
  border-radius: 4px;
  transition: 0.4s;
}

.reg {
  color: white;
  background-color: rgb(248, 235, 91);
}

.reg::after {
  position: absolute;
  content: '';
  background-color: rgb(255, 187, 50);
  height: 2px;
  margin: 22px 20px 20px -33%;
  margin-right: 50%;
width: 30%;
  color: white;
}


.log {
  color: rgb(104, 85, 224);
  background-color: rgba(255, 255, 255, 1);
  border: 1px solid rgb(254, 192, 58);
}

button:hover {
  box-shadow: 0 0 20px rgba(53, 21, 21, 0.546);
  border: 2px solid rgb(255, 255, 255);
}


.event{
  text-align: center;
  font-size: 20px;
  margin-top: 10%;
  color: white;

   

}

.event_create{
  position: relative;
  display: block;
  justify-content: center;
  font-size: 20px;


}

.event_create button{
  border-radius: 10px;
  text-align: center;
  font-size: 20px;

}


.event_create input{
  border-radius: 10px;
  text-align: center;
  font-size: 20px;
}

.form_btn{
  border-radius: 50px;
  border: none;
  font-weight: 100;
}

.form_btn button{
  margin: 10px;
  border-radius: 50px;
  border: none;
}





.titles{
padding: 5px;
border: solid rgb(41, 35, 35);
background-color: #24211ba7;
width: 300px;
line-height: 40px;
border-radius: 20px;
font-size: medium;

 
}

input{
  padding: 5px;
  text-align: left;
 }

 textarea{
border-radius: 20px;
height: 20vh;
width: 85%;
padding: 10px;
font-size: 20px;  
font-weight: 600;

 }


.event_details{

  color: rgb(255, 243, 243);
  padding: 2px;
  border-radius: 10px;
  font-size: 18px;
  line-height: 25px;
  height: fit-content;
  position: relative;
  justify-content:  center ;
  text-align: center;
  margin-top: 80px;
   
}

.event_backdrop{
  background-color: rgba(46, 40, 40, 0.281);
  padding: 60px 10px 10px 10px;
  border:solid 1px rgb(26, 13, 13);
  border-radius: 10px ;
  height: 100vh;
}



.eventDetails {
  text-align: left;
}





.btn_grp{
  position: relative;
  display: flex;
  justify-content: space-between;
  padding-top: 5px;
  padding-bottom: 0;
  background-color:#ff9d26;
  height: 7vh;
  border-top: 2px solid rgb(113, 42, 42);
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  
   h3{
    font-size: medium;
    padding-right: 15px;
   }
 }


 

.delete{
  width: fit-content;
  font-weight: 900;
  padding: 5px;
  font-size: 15px;
  border-radius: 10px;
  border: solid red;
  text-wrap: nowrap;
}

.edit{
  width: fit-content;
  font-weight: 900;
  padding: 5px;
  font-size: 15px;
  border-radius: 10px;
  text-wrap: nowrap;
}

.details{
  width: fit-content;
  font-weight: 600;
  border-radius: 10px;
  text-wrap: nowrap;
  height: fit-content;
  border: 3px solid  rgb(113, 42, 42);
  font-size: calc(15px + .5vmin);
  padding: 5px;
  margin-top: 5px;
}



.deleteBtnBackground{
  position: relative;
  display: flex;
  justify-content: space-between;
  height: fit-content;
  background-color:#ff9d26;
  padding: 5px;

  border-top: 2px solid rgb(113, 42, 42);

  border-radius: 10px;
}
.deleteBtn{
  width: fit-content;
  font-weight: 600;
  border-radius: 10px;
  text-wrap: nowrap;
  height: fit-content;
  border: 3px solid  rgb(113, 42, 42);
  font-size: calc(15px + .5vmin);
}

.delBtn{
  position: relative;
  display: flex;
  justify-content: center;
}

.evnt_details{
    line-height: 15px;

   
        h1{
          text-align: center;
          font-size: calc(15px + 2vmin);
        }

        h2{
          background-color: rgba(48, 27, 25, 0.459);
          padding: 10px;
          border-radius: 10px;

        }

        p{
          font-size: calc(10px + 2vmin);
         
        }

        div{
          background-color: rgba(36, 21, 21, 0.304) ;
          padding: 10px;
          border: solid rgba(69, 36, 36, 0.523);
          border-radius: 10px;
        }
}

.formDiv{
  position: relative;
  display: flex;
  justify-content: center;
}

.formDivAuth{
  position: relative;
  display: flex;
  justify-content: center;
}

.terms{
  position: relative;
  display: flex;
  justify-content: center;
  transform: translate(0,55%);
   button{
    background-color: #00000000;
    color: wheat;
    text-decoration: underline;
   }
}

.openTerms{
  z-index: 9999;
  background-color: wheat;
  position: absolute;
  border-radius: 10px;
  text-align: center;
  width: 95%;
  height: 90vw;
  transition-duration: 0.5s;
  transform: scale(1);
  padding: 10px;
  overflow-y:scroll;

  button{
    position: sticky;
    background-color: #3a3a3a;
    margin-left: 300px;
    color: wheat;
  }
  
}



.closedTerms{
  z-index: 9999;
  position: absolute;
  text-align: center;
  background-color: wheat;
  width: 95%;
  height: 90vw;
  overflow-y:scroll;

  transition-duration: .5s;
  transform: scale(0);
}


.openReport{
  background-color: rgb(16, 16, 14);
  position: absolute;
  border-radius: 10px;
  text-align: center;
  width: 95%;
  height: 90vw;
  transition-duration: 0.5s;
  transform: scale(1);
  padding: 10px;
  overflow-y:scroll;
  transform: translate(0,-50vh);

  
  p{
    background-color: rgb(255, 0, 0);
    color: white;
    border-radius: 10px;
    font-size: medium;
    font-weight: 100;
  }

  button{
    position: sticky;
    background-color: #3a3a3a;
    color: wheat;
  }

  h3 {
    background-color: wheat;
    border-radius: 10px;
  }
  
}

.radio{
  position: relative;
  display: flex;
  color: wheat;
  text-align: left;
}

.closedReport{
  position: absolute;
  text-align: center;
  background-color: wheat;

  transition-duration: .5s;
  transform-origin: center;
  transform: scale(000);

  

  button{
    position: sticky;
    background-color: #3a3a3a;
    color: wheat;
  }

  h3 {
    background-color: wheat;
    border-radius: 10px;
  }
}

.createAct{
  position: relative;
  display: flex;
  justify-content: center;

   button{
    border-radius: 50px;
    padding: 10px;
    font-weight: 600;
    background-color: #00000000;
    color: wheat;
    text-decoration: underline;
   }
}



.signupform{
  position: relative;
  display: inline-block;
  justify-content: center;
  margin-top: 20px;
  border-radius: 10px;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.498);
  backdrop-filter: blur(10px);
  width: 90%;
}

.signupform button{
  
color: white;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.498);
  width: calc(50%);
  font-size: calc(10px + 1vw);
  font-weight: 800;
  padding: 5px;
  height: 40px;
  border-radius: 50px;
  background-color: rgba(255, 166, 0, 0);
  border: solid orange;
  transform: translate(50%);
}

.signupform button:hover{
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.498);
  margin-bottom: 30px;
  font-size: calc(10px + 1vw);
  background-color: orange;
  color: rgb(0, 0, 0);
  border: solid rgb(255, 255, 255);
}

.signupform input{
  margin-top: calc(5px + 4vw);
  height:  calc(5px + 4vh);
  width: 95%;
  border-radius: 5px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.498);
  font-size: calc(5px + 4vw);
  background-color: rgba(0, 0, 0, 0);
  color: white;
  border: solid rgb(255, 255, 255);
}
.signupform input:focus{
  border: solid orange;
}



.signupform input::placeholder{
  color: rgb(255, 190, 117);
  font-style: italic ;
  font-weight: 100;
  font-size: calc(5px + 3vw);

}

.signupform button{
  
  color: white;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.498);
    width: calc(50%);
    font-size: calc(10px + 1vw);
    font-weight: 800;
    padding: 5px;
    height: 40px;
    border-radius: 50px;
    background-color: rgba(255, 166, 0, 0);
    border: solid orange;
    transform: translate(50%);
  }





  .signupform_auth{
    position: relative;
    display: inline-block;
    justify-content: center;
    margin-top: 20px;
    border-radius: 10px;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.498);
    backdrop-filter: blur(10px);
    width: 90%;
    margin-top:50%
  }
  
  .signupform_auth button{
    
  color: white;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.498);
    width: calc(50%);
    font-size: calc(10px + 1vw);
    font-weight: 800;
    padding: 5px;
    height: 40px;
    border-radius: 50px;
    background-color: rgba(255, 166, 0, 0);
    border: solid orange;
    transform: translate(50%);
    margin-bottom: 20px;
  }
  
  .signupform_auth button:hover{
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.498);
    background-color: orange;
    color: rgb(0, 0, 0);
    border: solid rgb(255, 255, 255);
  }
  
  .signupform_auth input{
    margin-top: calc(5px + 4vw);
    height:  calc(5px + 4vh);
    width: 95%;
    border-radius: 5px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.498);
    font-size: calc(5px + 4vw);
    background-color: rgba(0, 0, 0, 0);
    color: white;
    border: solid rgb(255, 255, 255);
  }
  .signupform_auth input:focus{
    border: solid orange;
  }
  
  .signupform_auth input::placeholder{
  color: rgb(255, 190, 117);
    
    font-style: italic ;
    font-weight: 100;
    font-size: calc(5px + 3vw);
    
  
  }
  
  .signupform_auth button{
    
    color: white;
      box-shadow: 0 0 10px rgba(0, 0, 0, 0.498);
      width: calc(50%);
      font-size: calc(10px + 1vw);
      font-weight: 800;
      padding: 5px;
      height: 40px;
      border-radius: 50px;
      background-color: rgba(255, 166, 0, 0);
      border: solid orange;
      transform: translate(50%);
    }
  




.descp{
  border: solid black 1px;
  border-radius: 12px;
  border: 2px solid rgb(0, 0, 0);
  color: #010102;
  font-weight: 600;
  position: relative;
  display: block;
  justify-content: left;


    h2{
      /* rgb(255, 153, 0) */
      
      background: linear-gradient(0.25turn,rgb(255, 153, 0),rgba(255, 153, 0, 0));
      text-align: left;
      padding-left: 25px;
      font-size: large;
      color: #010102;
      border-radius: 5px
    }
}

.descp_details{
  background-color: rgb(37, 36, 34);
  min-height: 10vh;
  padding: 5px;
  color: aliceblue;
  border-radius: 10px;
  margin: 5px;

  h2{
    margin: 5px 15px 15px 0px;
  }
}

.descp_header{
  color: rgb(252, 231, 176);
  margin-right: 20px;
  background-color: #000000da;
  width: 100%;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  padding-bottom: 1px;
  padding-top: 1px;

}

.beforeafter{
  padding-right: 10px;
}

.calendarOpen{
  font-size: small;
  border-radius: 20px;
    input{
      background-color: rgba(235, 195, 195, 0);
      height: 20px;
      width: 300px;
      border: none;
      color: #ffe8cd;
    }
}



.beforeafter::after{
  position: absolute;
  content: '';
  background-color: rgb(234, 222, 222);
  height: 1px;
  width: 80%;
  transform: translate(-50%,-10px);
  color: white;
}

.beforeafter::before{
  position: absolute;
  content: '';
  background-color: rgb(234, 222, 222);
  height: 1px;
  width: 80%;
  transform: translate(-50%,-10px);
  color: white;
}


.infoAfter
{display: block;
 padding:   5px;
 border-radius: 10px;
 margin-right:10px ;
 margin-left: 10px;
}

.infoAfter span
{display: block;
 background-color: rgb(54, 53, 53);
 padding:   5px;
 border-radius: 10px;
 margin-right:10px ;
 padding-left: 10px;

}

.inputField_textbox{
  position: relative;
  display: flex;
  margin-top: 20px;
  margin-bottom: 20px;
  background-color: rgba(0, 0, 0, 0);
  color: rgb(255, 255, 255);
  font-weight: 100;
  font-style: italic;
  font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
  width:  92%;
  border: solid rgb(255, 255, 255);
  
}

.failedLogin{
  background-color: rgb(161, 0, 0);
  color:white;
  text-align: center;
  box-shadow: 0px 2px 5px #010102;


  p{
    padding: 2px;
  }
}

.disclaimer{
  position: fixed;
  text-align: center;
  color: rgb(253, 254, 251);
  background-color: #17171a;
  padding: 5px;
  bottom: 0;
  width: 99vw;
 
}

.disclaimer span{
  color: red;
  text-decoration: underline ;
  font-weight: 600;
  padding: 5px;
}

.signuphead{
  position: relative;
  justify-content: center;
}

.SignupNav{
  position: relative;
  padding: 5px 20px 10px 20px;
  display: flex;
  justify-content: space-between;
  color: rgb(243, 238, 227);

 
}




.nav h3{
  margin-right: 20px;
  color: white;
}



.nav button{
  background-color: rgba(255, 255, 255, 0);
  box-shadow: none;
  font-size: medium;
  margin: 5px;
  padding: 5px;
  color: white;
  font-size: large;
}

.nav h2{
  
  font-size: calc(15px + 1vmin);
  color: white;
  text-shadow: 0.05em 0.1em #4b5d83;
  backdrop-filter: blur(10px) saturate(70%);
  padding-right: 40px;
  margin-top: 30px;
}

.nav p{
  
  font-size: calc(17px + 1vmin);
  color: white;
  text-shadow: 0.05em 0.1em #ff9d26;
  margin-top: 20px;
  padding-left: 20px
}

.navUser{
  position: fixed;
  width: 100vw;

  color: white;
  background-color: rgb(25, 21, 21);
  z-index: 999;

  box-shadow: 0px 5px 5px #010102;
  border-radius: 5px;
  padding-bottom: 5px;
  margin: 0;
  display: flex;
  justify-content: right;
  font-weight: 600;

  p{
    font-size: large;
  }
   button{
    width: fit-content;
    padding: 5px;
    padding-left: 8px;
    padding-right: 8px;
    color: rgb(59, 30, 30);
    border-radius: 20px;
    height: fit-content;
    margin-top: 13px;
    font-size: large;
    margin-right: 10px;
    font-weight: 600;
    border: solid 2px rgb(59, 30, 30);
   }

   button:hover{
    color: white;
   }

   p{
    margin-right: 10px;
   }
}


.navUserName{
  width: 100vw; 
  color: white;
  z-index: 999;
  border-radius: 5px;
  margin: 10;
  display: flex;
  justify-content: center;
  font-weight: 600;

  p{
    font-size: large;
  }
   button{
    width: fit-content;
    padding: 5px;
    padding-left: 8px;
    padding-right: 8px;
    color: rgb(59, 30, 30);
    border-radius: 20px;
    height: fit-content;
    margin-top: 13px;
    font-size: large;
    margin-right: 10px;
    font-weight: 600;
    border: solid 2px rgb(59, 30, 30);
   }

   button:hover{
    color: white;
   }

   p{
    margin-right: 10px;
   }
}
.userButton{

    display: flex;
    position: fixed;
    margin-top: 60px;
    justify-content: space-evenly;
    width: 100vw;
    z-index: 999;
    height: fit-content ;  
    background-color: #070303;
    padding-top: 20px;
    padding-bottom: 10px;

    button{
      height: 40px;
      width: 100px;
    }
}

.userButton button:focus{
  height: 40px;
}

.btn{
  display: flex;
  margin-top: 10px;
  justify-content:center ;
}


.nameList{
  background-color: rgb(49, 47, 47);
  color:rgb(248, 234, 208);
  height: fit-content;
  padding: 10px;
  margin-top: 5px;
  text-align: left;
  padding-top: 10px;
  font-weight: 600;
  border: solid orange;
  margin-bottom: 10px;
  border-radius: 10px;

}

.listbtn{
  background-color: #01010200;
  box-shadow: none;
  font-size: 18px;
  text-decoration: underline;
  border: dashed black;
  margin-bottom: 10px;
}

.listbtn:hover{
  background-color: #01010200;
  color: rgb(50, 1, 1);
  box-shadow: none;
}

.listbtn:focus{
  background-color: #01010200;
  color: rgb(50, 1, 1);
  box-shadow: none;
  border: rgb(255, 175, 4) solid;
}

.report{
  width: fit-content;
  font-weight: 500;
  border-radius: 10px;
  text-wrap: nowrap;
  height: fit-content;
  border: 3px solid  rgb(113, 42, 42);
  font-size: calc(15px + .5vmin);
  padding: 5px;
  margin-top: 5px;
  background-color: rgba(254, 254, 254, 0);


}

.rsvp{
  position: relative;
  display: flex;
  justify-content: center;
  padding-left: 10px;
}

.simple{
  margin-top: 120px;
}

.event{
  margin-top: 20px
}


.myeventBtn[mybtn='0']{
  color: white;
  box-shadow: 0 0 20px rgba(53, 21, 21, 0.546);
  background-color: rgb(49, 12, 12);
  border: 2px solid rgb(255, 255, 255);
}

.rsvpBtn{
  z-index: 3;
  
}

.notInterested{
  background-color: rgb(243, 74, 74);
  color: white;
  padding: 10px;
  border-radius: 50px;
  font-size: medium;
  border: solid wheat 1px;
  
}

.notInterested:focus{
  background-color: rgb(243, 74, 74);
  color: white;
  padding: 10px;
  border-radius: 50px;
  font-size: medium;

  
}

.interested{
  background-color: green;
  color: white;
  padding: 10px;
  border-radius: 50px;
  font-size: medium;
  border: solid wheat 1px;

}

.interested:focus{
  background-color: green;
  color: white;
  padding: 10px;
  border-radius: 50px;
  font-size: medium;
}

.locDiv{
  margin-top: 10px;
}

.nameIcon{
  margin-right: 5px;
  color: #010102;
  background-color: #ff9d26;
  border: solid 1px rgb(0, 0, 0);
  border-radius: 100px;
  padding: 3px;
  padding-top: 5px;
  width: 28px;
  height: 28px;
  display: inline-block;
  text-align:center;

    
}

.nameIcon_nav{
  margin-right: 5px;
  margin-top: 10px;
  color: #010102;
  background-color: #ff9d26;
  border: solid 1px rgb(0, 0, 0);
  border-radius: 100px;
  width: 36px;
  height: 30px;
  display: inline-block;
  text-align:center;
  padding-top: 8px;

    
}

li{
  list-style-type: none;
}

.map{
  border: 2px solid rgb(0, 0, 0);
  border-radius: 10px;
  box-shadow: 1px 1px 10px black;
}

.event_middle::after{
  position: absolute;
  content: '';
  background-color: rgba(42, 41, 41, 0.507);
  height: 1px;
  width: 80%;
  transform: translate(-50%,-10px);
  color: white;

}

















 .rdt {
  position: relative;
  display: flex;
  justify-content: center;


}
.rdtPicker {
  display: none;
  position: absolute;
  min-width: 250px;
  padding: 4px;
  margin-top: 0px;
  z-index: 99999 !important;
  background: #ffcf7d;
  box-shadow: 0 1px 3px rgba(0,0,0,.1);
  border: 2px solid #000000;
  transform: translate(0vw,-30vh) scale(1.2);

  border-radius: 10px;
   placeholder{
    color: red;
   }
}
.rdtOpen .rdtPicker {
  display: block;
}
.rdtStatic .rdtPicker {
  box-shadow: none;
  position: static;
}

.rdtPicker .rdtTimeToggle {
  text-align: center;
  background-color: #fa0e0ed4;
  color: white;
  border-radius: 10px;
}

.rdtPicker table {
  width: 100%;
  margin: 0;
}
.rdtPicker td,
.rdtPicker th {
  text-align: center;
  height: 28px;
}
.rdtPicker td {
  cursor: pointer;
}
.rdtPicker td.rdtDay:hover,
.rdtPicker td.rdtHour:hover,
.rdtPicker td.rdtMinute:hover,
.rdtPicker td.rdtSecond:hover,
.rdtPicker .rdtTimeToggle:hover {
  background: #eeeeee;
  cursor: pointer;
}
.rdtPicker td.rdtOld,
.rdtPicker td.rdtNew {
  color: #999999;
}
.rdtPicker td.rdtToday {
  position: relative;
}
.rdtPicker td.rdtToday:before {
  content: '';
  display: inline-block;
  border-left: 7px solid transparent;
  border-bottom: 7px solid #010101;
  border-top-color: rgba(0, 0, 0, 0.2);
  position: absolute;
  bottom: 4px;
  right: 4px;
}
.rdtPicker td.rdtActive,
.rdtPicker td.rdtActive:hover {
  background-color: #428bca01;
  color: #0c0c0c;
  text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.25);
  font-weight: bolder;
  border: 1px black solid;
}
.rdtPicker td.rdtActive.rdtToday:before {
  border-bottom-color: #fff;
}
.rdtPicker td.rdtDisabled,
.rdtPicker td.rdtDisabled:hover {
  background: none;
  color: #999999;
  cursor: not-allowed;
}

.rdtPicker td span.rdtOld {
  color: #999999;
}
.rdtPicker td span.rdtDisabled,
.rdtPicker td span.rdtDisabled:hover {
  background: none;
  color: #999999;
  cursor: not-allowed;
}
.rdtPicker th {
  border-bottom: 1px solid #f9f9f9;
}
.rdtPicker .dow {
  width: 14.2857%;
  border-bottom: none;
  cursor: default;
}
.rdtPicker th.rdtSwitch {
  width: 100px;
  background-color: rgb(23, 22, 22);
  color: white;
  border-radius: 10px;
}


td.rdtSwitch{
  background-color: #fa0e0ed4;
  
  color: rgb(255, 255, 254);
  border-radius: 10px;
  border: solid 1px black;
}

.rdtPicker th.rdtNext,
.rdtPicker th.rdtPrev {
  font-size: 21px;
  vertical-align: top;
  background-color: rgb(244, 157, 36);
  border: solid black 1px;
  border-radius: 10px;
}

.rdtPrev span,
.rdtNext span {
  display: block;
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none;   /* Chrome/Safari/Opera */
  -khtml-user-select: none;    /* Konqueror */
  -moz-user-select: none;      /* Firefox */
  -ms-user-select: none;       /* Internet Explorer/Edge */
  user-select: none;
}

.rdtPicker th.rdtDisabled,
.rdtPicker th.rdtDisabled:hover {
  background: none;
  color: #999999;
  cursor: not-allowed;
}
.rdtPicker thead tr:first-of-type th {
  cursor: pointer;
}
.rdtPicker thead tr:first-of-type th:hover {
  background: #eeeeee;
}

.rdtPicker tfoot {
  border-top: 1px solid #c51717;
}

.rdtPicker button {
  border: none;
  background: none;
  cursor: pointer;
}
.rdtPicker button:hover {
  background-color: #eee;
}

.rdtPicker thead button {
  width: 100%;
  height: 100%;
}

td.rdtMonth,
td.rdtYear {
  height: 50px;
  width: 25%;
  cursor: pointer;
}
td.rdtMonth:hover,
td.rdtYear:hover {
  background: #eee;
}

.rdtCounters {
  display: flex;
  justify-content: center;
  background-color: rgb(6, 6, 6);
  color: wheat;
  border-radius: 10px;
  width: 100%;
}

.rdtCounters > div {
  float: left;
}

.rdtCounter {
  height: 100px;
}

.rdtCounter {
  width: 40px;
}

.rdtCounterSeparator {
  line-height: 100px;
}

.rdtCounter .rdtBtn {
  height: 40%;
  line-height: 40px;
  cursor: pointer;
  display: block;

  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none;   /* Chrome/Safari/Opera */
  -khtml-user-select: none;    /* Konqueror */
  -moz-user-select: none;      /* Firefox */
  -ms-user-select: none;       /* Internet Explorer/Edge */
  user-select: none;
}
.rdtCounter .rdtBtn:hover {
  background: #eee;
  border-radius: 10px;
  color: #000000;
}
.rdtCounter .rdtCount {
  height: 20%;
  font-size: 1.2em;
}

.rdtMilli {
  vertical-align: middle;
  padding-left: 8px;
  width: 48px;
}

.rdtMilli input {
  width: 100%;
  font-size: 1.2em;
  margin-top: 37px;
}

.rdtTime td {
  cursor: default;
}



.popUp{
  z-index: 9999;
  position:absolute;
  height: 200vh;
  width: 100vw;
  margin-left: 0px;
  background-color: rgba(0, 0, 0, 0.97);
  transform: translate(-20px,-200px);
  color: wheat;
  border-radius: 20px;

  h3{ position: relative;
      display: flex;
      justify-content: center;
      text-align: center;
      margin-top: 50vh;
      background-color: #000000;
  }
}


.span{
  color: rgba(255, 0, 0, 0);

}



.guestImg_dets{

  margin-bottom: 5px;
  img {
    transform: translate(-10px,-5px);
  }
}
.detsImg{
  position: relative;
  display: flex;
  justify-content:  center ;

}




.closedDiv{
   transform: scale(0);
}

.openDiv{
  transform: scale(1);
  transition: ease-in 0.2s;
  background-color: wheat;
  margin: 10px;
  padding: 1px;
  font-size: small;
  text-align: center;
  border-radius: 20px;
}


.webimg{
    display: flex;
    justify-content: space-between;
    margin: 10px;
    color: wheat;
      border: solid wheat;
      border-radius: 20px;
      padding: 10px;
    h2{
margin-top: 30px;
margin-right: 50px;
    }
  img{
    height: 70px;
    margin-top: 5px;
  }
}

.confirm{
  text-align: center;
  margin-top: 50%;
  background-color: rgb(244, 158, 0);
  margin-left: 10px;
  margin-right: 10px;
  border-radius: 20px;
  padding: 10px;
  border: solid rgb(0, 0, 0);

  
  button{
    background-color: rgba(255, 0, 0, 0);
    border: solid rgb(0, 0, 0);
    color: rgb(0, 0, 0);
    font-size: larger;
  }


    h2 {
      color: #000000;
    }

    p{
      background-color: #000000;
      color: white;
      border-radius: 10px;
      padding: 10px;
    }
}

.yesPass{
  transform: scale(1);
  transition: 0.2s;
  background-color: orange;
  text-align: center;
  margin-top: 50px;
  padding: 10px;

  span{
    display: none;
  }
  button{
    background-color: #00000000;
    border: solid black 1px;
  }
}

.errorPass{
  transform: scale(1);
  transition: 0.2s;
  background-color: rgb(255, 0, 0);
  color: white;
  text-align: center;
  margin-top: 50px;
  padding: 10px;

  h3{
    display: none;
  }
  button{
    display: none;
  }
}

.noPass{
  transform: scale(0);
}


.noDets{
  transform: scaleY(0);
  transition: .2s;
  div{
    display: none;
  transition: .2s;

  }
  }

.yesDets{
  z-index: 999;
  transform: scaleY(1);
  transition: 0.2s;

}

}